import Firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage'

// 1) when seeding the database you'll have to uncomment this!
//import { seedDatabase } from '../seed';

const config = {
  apiKey: "AIzaSyClgJyotv5Z7fVU851iSKSXQ0tlPGHerVs",
  authDomain: "christian-video-platform.firebaseapp.com",
  projectId: "christian-video-platform",
  storageBucket: "christian-video-platform.appspot.com",
  messagingSenderId: "1079070720761",
  appId: "1:1079070720761:web:879ba7df34d774a7fd9828",
  measurementId: "G-S8PC04G0PD"
};

const firebase = Firebase.initializeApp(config);
// 2) when seeding the database you'll have to uncomment this!
//seedDatabase(firebase);
// 3) once you have populated the database (only run once!), re-comment this so you don't get duplicate data

export { firebase };
