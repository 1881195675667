export default function selectionFilter({ series, films, testimonies } = []) {
  return {
    series: [
      { title: 'Bible Books', data: series?.filter((item) => item.genre === 'books') },
      { title: 'Preachers', data: series?.filter((item) => item.genre === 'preachers') },
    ],
    films: [
      { title: 'Bible Books', data: films?.filter((item) => item.genre === 'book') },
    ],
    testimonies: [
      { title: 'Life Stories', data: testimonies?.filter((item) => item.genre === 'lifestories') },
    ],
  };
}
