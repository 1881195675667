import React, { useState, useEffect, useContext } from 'react';
import Fuse from 'fuse.js';
import { Card, Header, Loading, Player } from '../components';
import * as ROUTES from '../constants/routes';
import logo from '../logo.svg';
import { FirebaseContext } from '../context/firebase';
import { SelectProfileContainer } from './profiles';
import { FooterContainer } from './footer';
import { firebase } from '../lib/firebase.prod';

export function BrowseContainer({ slides }) {
  const [category, setCategory] = useState('series');
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [slideRows, setSlideRows] = useState([]);

  const { firebase } = useContext(FirebaseContext);
  const user = firebase.auth().currentUser || {};

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [profile.displayName]);

  useEffect(() => {
    setSlideRows(slides[category]);
  }, [slides, category]);

  useEffect(() => {
    const fuse = new Fuse(slideRows, { keys: ['data.description', 'data.title', 'data.genre'] });
    const results = fuse.search(searchTerm).map(({ item }) => item);

    if (slideRows.length > 0 && searchTerm.length > 3 && results.length > 0) {
      setSlideRows(results);
    } else {
      setSlideRows(slides[category]);
    }
  }, [searchTerm]);

  return profile.displayName ? (
    <>
      {loading ? <Loading src={user.photoURL} /> : <Loading.ReleaseBody />}

      <Header src="background1" dontShowOnSmallViewPort>
        <Header.Frame>
          <Header.Group>
            <Header.Logo to={ROUTES.HOME} src={logo} alt="Gospify" />
            <Header.TextLink active={category === 'series' ? 'true' : 'false'} onClick={() => setCategory('series')}>
              Teaching Series
            </Header.TextLink>
            <Header.TextLink active={category === 'films' ? 'true' : 'false'} onClick={() => setCategory('films')}>
              Sermons
            </Header.TextLink>
            <Header.TextLink active={category === 'testimonies' ? 'true' : 'false'} onClick={() => setCategory('testimonies')}>
              Testimonies
            </Header.TextLink>
          </Header.Group>
          <Header.Group>
            <Header.Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <Header.Profile>
              <Header.Picture src={user.photoURL} />
              <Header.Dropdown>
                <Header.Group>
                  <Header.Picture src={user.photoURL} />
                  <Header.TextLink>{user.displayName}</Header.TextLink>
                </Header.Group>
                <Header.Group>
                  <Header.TextLink onClick={() => firebase.auth().signOut()}>Sign out</Header.TextLink>
                </Header.Group>
              </Header.Dropdown>
            </Header.Profile>
          </Header.Group>
        </Header.Frame>

        <Header.Feature>
          <Header.FeatureCallOut>Freedom in Christ</Header.FeatureCallOut>
          <Header.Text>
            In this sermon Dr. Billy Graham talks about how to live the christian life more effectively by looking at Christ. He shows
            how we can live meaningful lives in an ungodly world and how we can serve others while having legitimate needs ourselves.
          </Header.Text>
          <Player>
            <Player.ButtonWhite />
            <Player.Video src={fetchURLFromFirebase('videos/' + "billy_graham1.mp4")} src_string={'videos/' + "billy_graham1.mp4"} />
          </Player>
        </Header.Feature>
      </Header>
      <Card.Group>
        {slideRows.map((slideItem) => (
          <Card key={`${category}-${slideItem.title.toLowerCase()}`}>
            <Card.Title>{slideItem.title}</Card.Title>
            <Card.Entities>
              {slideItem.data.map((item) => (
                <Card.Item key={item.docId} item={item}>
                  <Card.Image src={fetchURLFromFirebase('images/' + item.imageSmall)} src_string={'images/' + item.imageSmall} />
                  <Card.Meta>
                    <Card.SubTitle>{item.title}</Card.SubTitle>
                    <Card.Text>{item.description}</Card.Text>
                  </Card.Meta>
                </Card.Item>
              ))}
            </Card.Entities>
            <Card.Feature category={category}>
            </Card.Feature>
          </Card>
        ))}
      </Card.Group>
      <FooterContainer />
    </>
  ) : (
      <SelectProfileContainer user={user} setProfile={setProfile} />
    );
}


/*
              <Player>
                <Player.Button />
                <Player.Video src={chooseIfSeriesOrFilm(slideItem.genre)} src_string={'videos/' + 'test.mp4'}/>
              </Player>
*/

// src={fetchURLFromFirebase('images/' + 'large.jpg')} src_string={'images/' + 'large.jpg'}
// <Card.Image src={`/images/${category}/${item.genre}/${item.slug}/small.jpg`} />


function fetchURLFromFirebase(url) {
  // Create a reference to the file we want to download

  // Get a reference to the storage service, which is used to create references in your storage bucket
  var storage = firebase.storage();

  // Create a storage reference from our storage service
  var storageRef = storage.ref();

  // Get the download URL
  var VideoURL = storageRef.child(url).getDownloadURL()
    .then((url) => {
      // console.log(url);
      return url;
      // Insert url into an <img> tag to "download"
    })
    .catch((error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/object-not-found':
          // File doesn't exist
          alert("Internal Error! File doesn't exist");
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          alert("Internal Error! you doen't have permission to access the object");
          break;
        case 'storage/canceled':
          alert("Internal Error! you canceled the upload");
          // User canceled the upload
          break;

        // ...

        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          alert("Internal Error! Unknown error occurred");
          break;
      }
      return "/videos/bunny.mp4";
    });
  return VideoURL;
}

