import React, { useState, useContext, createContext } from 'react';
import { firebase } from '../../lib/firebase.prod';
import { Player } from '../../components';

import {
  Container,
  Group,
  Title,
  SubTitle,
  Text,
  Feature,
  FeatureTitle,
  FeatureText,
  FeatureClose,
  FeatureAuthor,
  Content,
  Meta,
  Entities,
  Item,
  Image,
  CustomSelectBox,
} from './styles/card';

export const FeatureContext = createContext();

export default function Card({ children, ...restProps }) {
  const [showFeature, setShowFeature] = useState(false);
  const [itemFeature, setItemFeature] = useState({});

  return (
    <FeatureContext.Provider value={{ showFeature, setShowFeature, itemFeature, setItemFeature }}>
      <Container {...restProps}>{children}</Container>
    </FeatureContext.Provider>
  );
}

Card.Group = function CardGroup({ children, ...restProps }) {
  return <Group {...restProps}>{children}</Group>;
};

Card.Title = function CardTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};

Card.SubTitle = function CardSubTitle({ children, ...restProps }) {
  return <SubTitle {...restProps}>{children}</SubTitle>;
};

Card.Text = function CardText({ children, ...restProps }) {
  return <Text {...restProps}>{children}</Text>;
};

Card.Entities = function CardEntities({ children, ...restProps }) {
  return <Entities {...restProps}>{children}</Entities>;
};

Card.Meta = function CardMeta({ children, ...restProps }) {
  return <Meta {...restProps}>{children}</Meta>;
};

Card.Item = function CardItem({ item, children, ...restProps }) {
  const { setShowFeature, setItemFeature } = useContext(FeatureContext);
  return (
    <Item
      onClick={() => {
        setItemFeature(item);
        setShowFeature(true);
      }}
      {...restProps}
    >
      {children}
    </Item>
  );
};

Card.Image = function CardImage({ src, src_string, ...restProps }) {
  var image_id = "image-small" + src_string;
  //var src = fetchURLFromFirebase(src_string);
  waitForImageToLoad(src, image_id);
  return <Image id={image_id} {...restProps} />;
};

Card.Feature = function CardFeature({ children, category, ...restProps }) {
  const { showFeature, itemFeature, setShowFeature } = useContext(FeatureContext);
  // console.log(src);
  // console.log(fetchURLFromFirebase(src_string));
  // console.log(src_string);
  // console.log(itemFeature.video);
  // console.log(category);

  var image_id = "";
  if (showFeature) {
    var src = fetchURLFromFirebase('images/' + itemFeature.imageLarge);
    image_id = "image-large" + itemFeature.imageLarge;
    waitForImageToLoadLarge(src, image_id);
  }
  return showFeature && category == "series" ? (
    <Feature {...restProps} id={image_id} >
      <Content>
        <FeatureTitle>{itemFeature.title}</FeatureTitle>
        <FeatureAuthor>{"By: " + itemFeature.author + "  - " + itemFeature.year}</FeatureAuthor>
        <FeatureText>{itemFeature.description}</FeatureText>
        <FeatureClose onClick={() => setShowFeature(false)}>
          <img src="/images/icons/close.png" alt="Close" />
        </FeatureClose>

        <Group margin="30px 0" flexDirection="row" alignItems="center">
          <CustomSelectBox id={"series-select" + image_id} >
            {itemFeature.video.map((singleVideo, index) => (
              <option key={index} value={itemFeature.video[index]}>{(index + 1) + ". " + itemFeature.episode_title[index]}</option>
            ))}
          </CustomSelectBox>
          <FeatureText fontWeight="bold">
            {itemFeature.genre.charAt(0).toUpperCase() + itemFeature.genre.slice(1)}
          </FeatureText>
        </Group>
        <Player>
          <Player.Button />
          <Player.Video image_id={image_id} src={fetchURLFromFirebase('videos/' + itemFeature.video[0])} src_string={'videos/' + itemFeature.video[0]} />
        </Player>
        {children}
      </Content>
    </Feature>
  ) : null ||
    showFeature && category == "films" ? (
        <Feature {...restProps} id={image_id} >
          <Content>
            <FeatureTitle>{itemFeature.title}</FeatureTitle>
            <FeatureAuthor>{"By: " + itemFeature.author + "  - " + itemFeature.year}</FeatureAuthor>
            <FeatureText>{itemFeature.description}</FeatureText>
            <FeatureClose onClick={() => setShowFeature(false)}>
              <img src="/images/icons/close.png" alt="Close" />
            </FeatureClose>

            <Group margin="30px 0" flexDirection="row" alignItems="center">

              <FeatureText fontWeight="bold">
                {itemFeature.genre.charAt(0).toUpperCase() + itemFeature.genre.slice(1)}
              </FeatureText>
            </Group>
            <Player>
              <Player.Button />
              <Player.Video src={fetchURLFromFirebase('videos/' + itemFeature.video)} src_string={'videos/' + itemFeature.video} category={category} />
            </Player>
            {children}
          </Content>
        </Feature>
      ) : null ||
      showFeature && category == "testimonies" ? (
          <Feature {...restProps} id={image_id} >
            <Content>
              <FeatureTitle>{itemFeature.title}</FeatureTitle>
              <FeatureAuthor>{"By: " + itemFeature.author + "  - " + itemFeature.year}</FeatureAuthor>
              <FeatureText>{itemFeature.description}</FeatureText>
              <FeatureClose onClick={() => setShowFeature(false)}>
                <img src="/images/icons/close.png" alt="Close" />
              </FeatureClose>
  
              <Group margin="30px 0" flexDirection="row" alignItems="center">
  
                <FeatureText fontWeight="bold">
                  {itemFeature.genre.charAt(0).toUpperCase() + itemFeature.genre.slice(1)}
                </FeatureText>
              </Group>
              <Player>
                <Player.Button />
                <Player.Video src={fetchURLFromFirebase('videos/' + itemFeature.video)} src_string={'videos/' + itemFeature.video} category={category} />
              </Player>
              {children}
            </Content>
          </Feature>
        ) : null;
};

//<Maturity rating={itemFeature.maturity}>{itemFeature.maturity < 12 ? 'PG' : itemFeature.maturity}</Maturity>
//`/images/${category}/${itemFeature.genre}/${itemFeature.slug}/large.jpg`

//const delay = ms => new Promise(res => setTimeout(res, ms));

async function waitForImageToLoad(promise, image_id) {
  var linkToImage = await promise;
  var element = document.getElementById(image_id);
  if (element != null) {
    element.setAttribute('src', linkToImage);
  }
}

async function waitForImageToLoadLarge(promise, image_id) {
  var linkToImage = await promise;
  var element = document.getElementById(image_id);
  if (element != null) {
    var selectEpisode = document.getElementById("series-select" + image_id);
    if (selectEpisode != null) {
      selectEpisode.selectedIndex = 0; //Reset to first episode when switching between series
    }
    element.setAttribute('style', "background-image:url(" + linkToImage + ");");
  }
}

//TODO CLEANUP


function fetchURLFromFirebase(url) {
  // Create a reference to the file we want to download

  // Get a reference to the storage service, which is used to create references in your storage bucket
  var storage = firebase.storage();

  // Create a storage reference from our storage service
  var storageRef = storage.ref();

  // Get the download URL
  var VideoURL = storageRef.child(url).getDownloadURL()
    .then((url) => {
      // console.log(url);
      return url;
      // Insert url into an <img> tag to "download"
    })
    .catch((error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/object-not-found':
          // File doesn't exist
          alert("Internal Error! File doesn't exist");
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          alert("Internal Error! you doen't have permission to access the object");
          break;
        case 'storage/canceled':
          alert("Internal Error! you canceled the upload");
          // User canceled the upload
          break;

        // ...

        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          alert("Internal Error! Unknown error occurred");
          break;
      }
      return "/videos/bunny.mp4";
    });
  return VideoURL;
}

