import React from 'react';
import { Header } from '../components';
import * as ROUTES from '../constants/routes';
import logo from '../logo.svg';

import { useLocation } from 'react-router-dom'

function HeaderView() {
  const location = useLocation();
  console.log(location.pathname);
  return location.pathname;
}

export function HeaderContainer({ children }) {
  var currentRoute = HeaderView();
  if(currentRoute == "/signin")
  return (
    <Header>
      <Header.Frame>
        <Header.Logo to={ROUTES.HOME} src={logo} alt="Gospify" />
        <Header.ButtonLink to={ROUTES.SIGN_UP}>Sign Up</Header.ButtonLink>
      </Header.Frame>
      {children}
    </Header>
  );
  else
  return (
    <Header>
      <Header.Frame>
        <Header.Logo to={ROUTES.HOME} src={logo} alt="Gospify" />
        <Header.ButtonLink to={ROUTES.SIGN_IN}>Sign In</Header.ButtonLink>
      </Header.Frame>
      {children}
    </Header>
  );
}
