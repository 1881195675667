import React from 'react';
import { Footer } from '../components';

export function FooterContainer() {
  return (
    <Footer>
      <Footer.Title>Questions? Write us.</Footer.Title>
      <Footer.Break />
      <Footer.Row>
        <Footer.Column>
          <Footer.Link href="#">mail@gospify.com</Footer.Link>

        </Footer.Column>

        <Footer.Column>
          <Footer.Link href="#">+49/123456789</Footer.Link>

        </Footer.Column>

        <Footer.Column>
          <Footer.Link href="#">Data Privacy Policy</Footer.Link>

        </Footer.Column>

        <Footer.Column>
          <Footer.Link href="#">Imprint</Footer.Link>

        </Footer.Column>
      </Footer.Row>
      <Footer.Break />
      <Footer.Text>Gospify Germany</Footer.Text>
    </Footer>
  );
}
