import React, { useState, useContext, createContext } from 'react';
import ReactDOM from 'react-dom';
import { Container, Button, Overlay, Inner, Close, PlayButton } from './styles/player';
import { firebase } from '../../lib/firebase.prod';

export const PlayerContext = createContext();

export default function Player({ children, ...restProps }) {
  const [showPlayer, setShowPlayer] = useState(false);

  return (
    <PlayerContext.Provider value={{ showPlayer, setShowPlayer }}>
      <Container {...restProps}>{children}</Container>
    </PlayerContext.Provider>
  );
}

Player.Video = function PlayerVideo({ image_id, src, src_string, ...restProps }) {
  const { showPlayer, setShowPlayer } = useContext(PlayerContext);
  var video_id = "video-source" + src_string;
  if (document.getElementById("series-select" + image_id) != null) {
    var selectBox = document.getElementById("series-select" + image_id);
    video_id = 'video-sourcevideos/' + selectBox.value;
  }
  if (showPlayer)
    waitForVideoToBeLoaded(image_id, src, video_id);
  return showPlayer
    ? ReactDOM.createPortal(
      <Overlay onClick={() => setShowPlayer(false)} data-testid="player">
        <Inner>
          <video id="gospify-player" controls>
            <source id={video_id} src={src} type="video/mp4" />
          </video>
          <Close />
        </Inner>
      </Overlay>,
      document.body
    )
    : null;
};

Player.Button = function PlayerButton({ ...restProps }) {
  const { showPlayer, setShowPlayer } = useContext(PlayerContext);

  return (
    <Button onClick={() => setShowPlayer((showPlayer) => !showPlayer)} {...restProps}>
      Play
    </Button>
  );
};

Player.ButtonWhite = function PlayerButtonWhite({ ...restProps }) {
  const { showPlayer, setShowPlayer } = useContext(PlayerContext);

  return (
    <PlayButton onClick={() => setShowPlayer((showPlayer) => !showPlayer)} {...restProps}>
      Play
    </PlayButton>
  );
};

async function waitForVideoToBeLoaded(image_id, promise, video_id) {

  if (document.getElementById("series-select" + image_id) != null) {
    var selectBox = document.getElementById("series-select" + image_id);
    var promise = fetchURLFromFirebase('videos/' + selectBox.value);
    var resultOfPromise = await promise;
    var element = document.getElementById(video_id);
    if(element != null){
      element.setAttribute('src', resultOfPromise);
      document.getElementById("gospify-player").load();
    }
  }
  else {
    var linkToVideo = await promise;
    var element = document.getElementById(video_id);
    if(element != null){
      element.setAttribute('src', linkToVideo);
    }
  }
}

function fetchURLFromFirebase(url) {
  // Create a reference to the file we want to download

  // Get a reference to the storage service, which is used to create references in your storage bucket
  var storage = firebase.storage();

  // Create a storage reference from our storage service
  var storageRef = storage.ref();

  // Get the download URL
  var VideoURL = storageRef.child(url).getDownloadURL()
    .then((url) => {
      // console.log(url);
      return url;
      // Insert url into an <img> tag to "download"
    })
    .catch((error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/object-not-found':
          // File doesn't exist
          alert("Internal Error! File doesn't exist");
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          alert("Internal Error! you doen't have permission to access the object");
          break;
        case 'storage/canceled':
          alert("Internal Error! you canceled the upload");
          // User canceled the upload
          break;

        // ...

        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          alert("Internal Error! Unknown error occurred");
          break;
      }
      return "/videos/bunny.mp4";
    });
  return VideoURL;
}